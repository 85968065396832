import dataLayerPush from '../utils/dataLayerPush';
import addToCart from './addToCart';
import addToWishlist from './addToWishlist';
import selectItem from './selectItem';
import viewItem from './viewItem';
import search from './search';
import share from './share';
import shippingError from './shippingError';
import viewItemList from './viewItemList';
import virtualPageview from './virtualPageview';
import importTaxesInfoModalClose from './importTaxesInfoModalClose';
import calculateTaxes from './calculateTaxes';
import selectContent from './selectContent';
import selectPromotion from './selectPromotion';
import shipping from './shipping';
import viewPromotion from './viewPromotion';
import reviewFormSubmitModal from './reviewFormSubmitModal';
import { clickAttachImageEvent, clickAttachVideoEvent } from './reviewAttachMedia';
import { clickCondensedMediaImage, clickCondensedMediaVideo } from './reviewCondensedMedia';
var events = {
  'addToWishlist:click': [dataLayerPush(addToWishlist)],
  pageview: [dataLayerPush(search), dataLayerPush(virtualPageview)],
  'product:addToCart': [dataLayerPush(addToCart)],
  'product:calculateTaxes': [dataLayerPush(calculateTaxes)],
  'product:details': [dataLayerPush(viewItem)],
  'product:importTaxesClose:click': [dataLayerPush(importTaxesInfoModalClose)],
  'product:review:condensedMedia:image:click': [dataLayerPush(clickCondensedMediaImage)],
  'product:review:condensedMedia:video:click': [dataLayerPush(clickCondensedMediaVideo)],
  'product:reviewform:attachimage:click': [dataLayerPush(clickAttachImageEvent)],
  'product:reviewform:attachvideo:click': [dataLayerPush(clickAttachVideoEvent)],
  'product:reviewform:modal': [dataLayerPush(reviewFormSubmitModal)],
  'product:selectContent': [dataLayerPush(selectContent)],
  'product:selectPromotion': [dataLayerPush(selectPromotion)],
  'product:share': [dataLayerPush(share)],
  'product:shipping': [dataLayerPush(shipping)],
  'product:viewItemList': [dataLayerPush(viewItemList)],
  'product:viewPromotion': [dataLayerPush(viewPromotion)],
  'productlist:selectItem:click': [dataLayerPush(selectItem)],
  'shipping:response:error': [dataLayerPush(shippingError)]
};
export default events;