import placeholderAttributes from '../utils/placeholderAttributes';
var category = function category(_ref) {
  var data = _ref.data,
    structure = _ref.structure;
  var siteId = structure == null ? void 0 : structure.id;
  if (siteId === 'category') {
    var _structure$cookies, _structure$cookies$ac, _data$search, _data$category, _data$category$, _data$search2, _data$search3, _data$search3$paginat;
    var userId = (structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.customer_id) || null;
    var attributes = placeholderAttributes(structure);
    var attributionToken = (data == null ? void 0 : (_data$search = data.search) == null ? void 0 : _data$search.attributionToken) || null;
    var categoryLabel = data == null ? void 0 : (_data$category = data.category) == null ? void 0 : (_data$category$ = _data$category[0]) == null ? void 0 : _data$category$.id;
    var pageCategories = [categoryLabel];
    var products = (data == null ? void 0 : (_data$search2 = data.search) == null ? void 0 : _data$search2.products) || [];
    var offset = data == null ? void 0 : (_data$search3 = data.search) == null ? void 0 : (_data$search3$paginat = _data$search3.pagination) == null ? void 0 : _data$search3$paginat.size;
    var productDetails = products.map(function (product) {
      return {
        product: {
          id: product.variationId
        }
      };
    });
    return {
      cloud_retail: {
        attributes: attributes,
        attributionToken: attributionToken,
        eventType: 'search',
        offset: offset,
        pageCategories: pageCategories,
        productDetails: productDetails,
        userInfo: {
          userId: userId
        }
      },
      event: 'cloud_retail'
    };
  }
  return null;
};
export default category;