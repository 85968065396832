var placeholderAttributes = function placeholderAttributes(structure) {
  var _crypto, _structure$cookies, _structure$cookies2, _structure$cookies3;
  var fallbackTrackId = (_crypto = crypto) == null ? void 0 : _crypto.randomUUID == null ? void 0 : _crypto.randomUUID();
  var oldMlTid = (structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : _structure$cookies.trackId) || 'PLACEHOLDER_ERROR';
  var newMlTid = (structure == null ? void 0 : (_structure$cookies2 = structure.cookies) == null ? void 0 : _structure$cookies2.trackIdPlaceholder) || 'PLACEHOLDER_ERROR';
  var newMlTidWithFallback = (structure == null ? void 0 : (_structure$cookies3 = structure.cookies) == null ? void 0 : _structure$cookies3.trackIdPlaceholder) || fallbackTrackId;
  return {
    from_new_app: {
      text: ['false']
    },
    new_ml_tid: {
      text: [newMlTid]
    },
    new_ml_tid_with_fallback: {
      text: [newMlTidWithFallback]
    },
    old_ml_tid: {
      text: [oldMlTid]
    }
  };
};
export default placeholderAttributes;