import { slug } from '@magalu/mixer-utils';
var shipping = function shipping(data, _ref) {
  var _data$deliveries, _data$deliveries$, _data$deliveries$$ite, _data$deliveries$$ite2, _data$deliveries2, _data$deliveries2$, _data$deliveries2$$st, _data$deliveries3, _data$deliveries3$, _data$deliveries3$$mo, _data$deliveries3$$mo2, _data$deliveries4, _data$deliveries4$, _data$deliveries4$$mo, _data$deliveries4$$mo2, _data$deliveries5, _data$deliveries5$;
  var structure = _ref.structure;
  var skuProduct = (data == null ? void 0 : (_data$deliveries = data.deliveries) == null ? void 0 : (_data$deliveries$ = _data$deliveries[0]) == null ? void 0 : (_data$deliveries$$ite = _data$deliveries$.items) == null ? void 0 : (_data$deliveries$$ite2 = _data$deliveries$$ite[0].seller) == null ? void 0 : _data$deliveries$$ite2.sku) || structure.route.productId || null;
  var productAvailability = (data == null ? void 0 : (_data$deliveries2 = data.deliveries) == null ? void 0 : (_data$deliveries2$ = _data$deliveries2[0]) == null ? void 0 : (_data$deliveries2$$st = _data$deliveries2$.status) == null ? void 0 : _data$deliveries2$$st.code) || null;
  var standardDelivery = slug((data == null ? void 0 : (_data$deliveries3 = data.deliveries) == null ? void 0 : (_data$deliveries3$ = _data$deliveries3[0]) == null ? void 0 : (_data$deliveries3$$mo = _data$deliveries3$.modalities) == null ? void 0 : (_data$deliveries3$$mo2 = _data$deliveries3$$mo[0]) == null ? void 0 : _data$deliveries3$$mo2.name) || 'null');
  var pickUpStore = slug((data == null ? void 0 : (_data$deliveries4 = data.deliveries) == null ? void 0 : (_data$deliveries4$ = _data$deliveries4[0]) == null ? void 0 : (_data$deliveries4$$mo = _data$deliveries4$.modalities) == null ? void 0 : (_data$deliveries4$$mo2 = _data$deliveries4$$mo[1]) == null ? void 0 : _data$deliveries4$$mo2.name) || 'null');
  var deliveryId = (data == null ? void 0 : (_data$deliveries5 = data.deliveries) == null ? void 0 : (_data$deliveries5$ = _data$deliveries5[0]) == null ? void 0 : _data$deliveries5$.id) || (data == null ? void 0 : data.exceptionDeliveriesId) || null;
  var zipCode = (data == null ? void 0 : data.zipCode) || null;
  var status = data.status,
    errorMessageServer = data.errorMessageServer,
    errorMessageUser = data.errorMessageUser;
  return {
    basketId: null,
    cep: zipCode,
    code: "".concat(slug(status) || null, ":").concat(slug(errorMessageServer) || null),
    content_id: slug(errorMessageUser) || 'null',
    content_type: 'produto:frete',
    deliveriesId: deliveryId,
    event: 'shipping',
    item_id: "".concat(skuProduct, ":").concat(productAvailability, ":").concat(standardDelivery, ":").concat(pickUpStore),
    screenName: 'produto'
  };
};
export default shipping;