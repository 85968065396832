var shippingError = function shippingError(data) {
  var _data$value, _data$value$error, _data$value2, _data$value3, _data$value4, _data$value5, _data$value6, _data$value7;
  if (!(data == null ? void 0 : (_data$value = data.value) == null ? void 0 : _data$value.errorCode)) return null;
  var errorMessage = (_data$value$error = data == null ? void 0 : (_data$value2 = data.value) == null ? void 0 : _data$value2.error) !== null && _data$value$error !== void 0 ? _data$value$error : 'cep-invalido';
  return {
    action: 'impression',
    cep: (data == null ? void 0 : (_data$value3 = data.value) == null ? void 0 : _data$value3.zipCode) || null,
    code: data == null ? void 0 : (_data$value4 = data.value) == null ? void 0 : _data$value4.errorCode,
    content_type: "".concat((data == null ? void 0 : (_data$value5 = data.value) == null ? void 0 : _data$value5.pageId) || null, ":frete"),
    deliveriesId: (data == null ? void 0 : (_data$value6 = data.value) == null ? void 0 : _data$value6.deliveriesId) || null,
    event: 'shipping_error',
    item_id: "".concat((data == null ? void 0 : (_data$value7 = data.value) == null ? void 0 : _data$value7.productId) || null, ":").concat(errorMessage).substring(0, 99)
  };
};
export default shippingError;