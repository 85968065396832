import dataLayerPush from '../utils/dataLayerPush';
import { addToCartError, addToCartSuccess } from './addtocart';
import { buyBoxButtonClick, buyBoxSeeMoreOffers } from './buybox';
import { buyButtonClick } from './buybutton';
import { postClick } from './blogPost';
import continueToCart from './continueToCart';
import pageView from './pageView';
import { productListClick, productListShowMore, productListShowFullList, productView, variationClick, startExperiment } from './productList';
import { paginateClick } from './paginate';
import reviewFilterClick from './reviewFilterClick';
import { zipCodeBarClick, zipCodeBarResponseSuccess, zipCodeBarResponseError, zipCodeBarTooltipClick, zipCodeBarTooltipClose, zipCodeBarTooltipView } from './zipCodeBar';
import { signInClick } from './signin';
import { searchSubmit, searchClear, searchClose, suggestionClick, topTermClick } from './search';
import { hotlinkClick } from './hotlinks';
import { gateKeeperButtonClick } from './gatekeeperbutton';
import { luContentView, luContentClick, luContentSocialMediaClick } from './luContent';
import { menuClick, menuHeaderClick } from './menu';
import { suggestionLinkClick } from './linkSuggestions';
import { suggestionImpression } from './suggestionImpression';
import { subcategoryTopClick, subcategoryListClick, subcategoryFullListClick } from './subcategoryList';
import onDisplayTypeChange from './displayType';
import onSortChange from './sort';
import { filterClean, filterClose, filterOpen, filterDeliverySelect, filterDeliveryClear, filterItemClick, filterLinkListClick, filterRemove, filterTypeClick, fastFilterClick, fastFilterView } from './filter';
import { orderEligibleListOnClick, orderEligibleListOnView, orderEligibleListOnViewItemList, orderNotEligibleOnView } from './orderEligibleList';
import { productAttributeClick, productPaymentMethodChange, productPaymentMethodClick } from './product';
import { shippingClick, shippingError, shippingImpression } from './shipping';
import { productReviewClick, productReviewSubmit, reviewListSort, reviewPdpClick, reviewPageView, writeReviewClick } from './review';
import { shareBlogButtonClick } from './blogbutton';
import viewItem from './viewItem';
import { miniAppLoad } from './miniapps';
import { clickFloatButtonForm, clickMiniappFloatButtonForm, questionListImpression, questionPageView, questionClickOpenConfirm, questionClickConfirmQuestion, questionFeedbackResponseError, questionFormSeeMore } from './questions';
import { buildBlogPageView } from './blogPageView';
import { sellerDetailsOffersLinkClick, sellerDetailsPdpLinkClick, sellerScoreImpression, sellerDetailsClickOpenModal, sellerDetailsRatingClick } from './sellerDetails';
import { addService, clickService, refuseService, servicesKnowMore, servicesLegalInfo, servicesMoreDetails, servicesPageView, servicesOfferChange, servicesVideo } from './services';
import { productTagsClick } from './productTags';
import { productLabelClick } from './productLabels';
import { productBenefitsLinkClick, productBenefitsView } from './productBenefits';
import { chatbotLuClick, chatbotLuImpression } from './chatbotLu';
import { conversationOnView, inboxConversationListOnView, inboxConversationOnClick } from './sellerChat';
import reviewFormSubmit from './reviewFormSubmit';
import { financialServicesSeparateSaleOnView, financialServicesSeparateSaleOnChange, financialServicesSeparateSaleSeeMoreOnClick, financialServicesSeparateSaleDetailsOnClick, financialServicesSeparateSaleVideoOnClick, financialServicesSeparateSalePaymentOnClick, financialServicesSeparateSaleTermOnClick } from './financialServicesSeparateSale';
import { buybuttonServicesOnClick } from './buybuttonServices';
var events = {
  'action:click': [dataLayerPush(signInClick)],
  'addtocart:error': [dataLayerPush(addToCartError)],
  'addtocart:success': [dataLayerPush(addToCartSuccess)],
  'blogpost:click': [dataLayerPush(postClick)],
  'buybox:addtocart': [dataLayerPush(buyBoxButtonClick)],
  'buybox:seemoreoffers': [dataLayerPush(buyBoxSeeMoreOffers)],
  'buybutton-services:click': [dataLayerPush(buybuttonServicesOnClick)],
  'buybuttons:click': [dataLayerPush(buyButtonClick)],
  'chatbotLu:click': [dataLayerPush(chatbotLuClick)],
  'chatbotLu:view': [dataLayerPush(chatbotLuImpression)],
  'continuetocart:click': [dataLayerPush(continueToCart)],
  'delivery:filter:clear': [dataLayerPush(filterDeliveryClear)],
  'delivery:filter:selected': [dataLayerPush(filterDeliverySelect)],
  'financial-services-separate-sale:change': [dataLayerPush(financialServicesSeparateSaleOnChange)],
  'financial-services-separate-sale:details:click': [dataLayerPush(financialServicesSeparateSaleDetailsOnClick)],
  'financial-services-separate-sale:payment:click': [dataLayerPush(financialServicesSeparateSalePaymentOnClick)],
  'financial-services-separate-sale:seemore:click': [dataLayerPush(financialServicesSeparateSaleSeeMoreOnClick)],
  'financial-services-separate-sale:term:click': [dataLayerPush(financialServicesSeparateSaleTermOnClick)],
  'financial-services-separate-sale:video:click': [dataLayerPush(financialServicesSeparateSaleVideoOnClick)],
  'financial-services-separate-sale:view': [dataLayerPush(financialServicesSeparateSaleOnView)],
  'gatekeeper:click': [dataLayerPush(gateKeeperButtonClick)],
  'hotlink:click': [dataLayerPush(hotlinkClick)],
  'link-suggestions:click': [dataLayerPush(suggestionLinkClick)],
  'link-suggestions:impression': [dataLayerPush(suggestionImpression)],
  'listlink:click': [dataLayerPush(filterLinkListClick)],
  'lucontent:click': [dataLayerPush(luContentClick)],
  'lucontent:socialmedia:click': [dataLayerPush(luContentSocialMediaClick)],
  'lucontent:view': [dataLayerPush(luContentView)],
  'menu:click': [dataLayerPush(menuClick)],
  'menu:header:click': [dataLayerPush(menuHeaderClick)],
  'order-eligible-list:click': [dataLayerPush(orderEligibleListOnClick)],
  'order-eligible-list:impression': [dataLayerPush(orderEligibleListOnViewItemList)],
  'order-eligible-list:onview': [dataLayerPush(orderEligibleListOnView)],
  'order-not-eligible:impression': [dataLayerPush(orderNotEligibleOnView)],
  pageview: [dataLayerPush(pageView), dataLayerPush(questionPageView), dataLayerPush(miniAppLoad), dataLayerPush(buildBlogPageView), dataLayerPush(reviewPageView), dataLayerPush(servicesPageView)],
  'paginate:click': [dataLayerPush(paginateClick)],
  'product:attribute:click': [dataLayerPush(productAttributeClick)],
  'product:benefits:link:click': [dataLayerPush(productBenefitsLinkClick)],
  'product:benefits:tags:click': [dataLayerPush(productTagsClick)],
  'product:benefits:view': [dataLayerPush(productBenefitsView)],
  'product:details': [dataLayerPush(viewItem)],
  'product:label:click': [dataLayerPush(productLabelClick)],
  'product:paymentmethod:change': [dataLayerPush(productPaymentMethodChange)],
  'product:paymentmethod:click': [dataLayerPush(productPaymentMethodClick)],
  'product:review:click': [dataLayerPush(productReviewClick)],
  'product:review:submit': [dataLayerPush(productReviewSubmit)],
  'product:reviewform:submit': [dataLayerPush(reviewFormSubmit)],
  'product:shipping:change': [dataLayerPush(shippingImpression)],
  'product:shipping:click': [dataLayerPush(shippingClick)],
  'product:shipping:error': [dataLayerPush(shippingError)],
  'product:view': [dataLayerPush(productView)],
  'productlist:click': [dataLayerPush(productListClick)],
  'productlist:display:change': [dataLayerPush(onDisplayTypeChange)],
  'productlist:fastFilter:click': [dataLayerPush(fastFilterClick)],
  'productlist:fastFilter:view': [dataLayerPush(fastFilterView)],
  'productlist:filter:clean': [dataLayerPush(filterClean)],
  'productlist:filter:click': [dataLayerPush(filterItemClick)],
  'productlist:filter:close': [dataLayerPush(filterClose)],
  'productlist:filter:open': [dataLayerPush(filterOpen)],
  'productlist:filter:remove': [dataLayerPush(filterRemove)],
  'productlist:filter:showdetail': [dataLayerPush(filterTypeClick)],
  'productlist:showFullList': [dataLayerPush(productListShowFullList)],
  'productlist:showmore': [dataLayerPush(productListShowMore)],
  'productlist:sort:change': [dataLayerPush(onSortChange)],
  'productlist:start:experiment': [dataLayerPush(startExperiment)],
  'question:form:submit': [dataLayerPush(questionClickOpenConfirm)],
  'question:miniapp:open:float:modal': [dataLayerPush(clickMiniappFloatButtonForm)],
  'question:open:float:modal': [dataLayerPush(clickFloatButtonForm)],
  'question-confirm:click': [dataLayerPush(questionClickConfirmQuestion)],
  'question-feedback:error': [dataLayerPush(questionFeedbackResponseError)],
  'question-form:see-more': [dataLayerPush(questionFormSeeMore)],
  'question-list:impression': [dataLayerPush(questionListImpression)],
  'review:filter:click': [dataLayerPush(reviewFilterClick)],
  'reviewlist:button:click': [dataLayerPush(writeReviewClick)],
  'reviewlist:sort:change': [dataLayerPush(reviewListSort)],
  'reviewpdp:button:click': [dataLayerPush(reviewPdpClick)],
  'search:clear': [dataLayerPush(searchClear)],
  'search:close': [dataLayerPush(searchClose)],
  'search:submit': [dataLayerPush(searchSubmit)],
  'sellerchat:conversation:onview': [dataLayerPush(conversationOnView)],
  'sellerchat:inbox:conversations:onclick': [dataLayerPush(inboxConversationOnClick)],
  'sellerchat:inbox:conversations:onview': [dataLayerPush(inboxConversationListOnView)],
  'sellerdetails:click:about:rating': [dataLayerPush(sellerDetailsRatingClick)],
  'sellerdetails:impression': [dataLayerPush(sellerScoreImpression)],
  'sellerdetails:offers:link:click': [dataLayerPush(sellerDetailsOffersLinkClick)],
  'sellerdetails:open:modal:click': [dataLayerPush(sellerDetailsClickOpenModal)],
  'sellerdetailspdp:open:modal:click': [dataLayerPush(sellerDetailsPdpLinkClick)],
  'services:add': [dataLayerPush(addService)],
  'services:click': [dataLayerPush(clickService)],
  'services:know-more': [dataLayerPush(servicesKnowMore)],
  'services:legal-info': [dataLayerPush(servicesLegalInfo)],
  'services:more-details': [dataLayerPush(servicesMoreDetails)],
  'services:offer-change': [dataLayerPush(servicesOfferChange)],
  'services:refuse': [dataLayerPush(refuseService)],
  'services:video': [dataLayerPush(servicesVideo)],
  'shareblog:click': [dataLayerPush(shareBlogButtonClick)],
  'subcategoryfulllist:click': dataLayerPush(subcategoryFullListClick),
  'subcategorylist:click': dataLayerPush(subcategoryListClick),
  'subcategorytop:click': dataLayerPush(subcategoryTopClick),
  'suggestion:click': [dataLayerPush(suggestionClick)],
  'suggestion:topterm:click': [dataLayerPush(topTermClick)],
  'variation:click': [dataLayerPush(variationClick)],
  'zipcodebar:click': [dataLayerPush(zipCodeBarClick)],
  'zipcodebar:popup:click': [dataLayerPush(zipCodeBarTooltipClick)],
  'zipcodebar:popup:close': [dataLayerPush(zipCodeBarTooltipClose)],
  'zipcodebar:popup:success': [dataLayerPush(zipCodeBarTooltipView)],
  'zipcodebar:popup:view': [dataLayerPush(zipCodeBarTooltipView)],
  'zipcodebar:response:error': [dataLayerPush(zipCodeBarResponseError)],
  'zipcodebar:response:success': [dataLayerPush(zipCodeBarResponseSuccess)]
};
export default events;