import { magaluCompanies, slug } from '@magalu/mixer-utils';
import { wishlistItem } from './wishlistItem';
import { getNominalDiscountValue, getPercentualDiscountValue } from './discountCalc';
import { buildDimension9 } from './buildDimensions';
import { formattedTagSeal } from './formattedTagSelo';
var buildDimension7 = function buildDimension7(priceData) {
  var price = (priceData == null ? void 0 : priceData.price) || null;
  var bestPrice = (priceData == null ? void 0 : priceData.bestPrice) || null;
  var fullPrice = (priceData == null ? void 0 : priceData.fullPrice) || null;
  return "preco-de:".concat(price !== null ? price : 'null', "|pix:").concat(bestPrice !== null ? bestPrice : 'null', "|mpay:null|boleto:").concat(fullPrice !== null ? fullPrice : 'null');
};
var buildDimension23 = function buildDimension23(seller, togglePdpSellerScore) {
  var _seller$details, _seller$details$score;
  return seller.category === '1p' || !!magaluCompanies[seller.id] ? '0' : "".concat(Number(!!togglePdpSellerScore) || '1', ":").concat(((_seller$details = seller.details) == null ? void 0 : (_seller$details$score = _seller$details.score) == null ? void 0 : _seller$details$score.toString()) || null);
};
var buildDimension41 = function buildDimension41(offerTags) {
  return "etiqueta:".concat((offerTags == null ? void 0 : offerTags.includes('magalu_indica')) ? '1:magalu-indica' : '0');
};
var buildDimension112 = function buildDimension112(shipping) {
  if (!shipping) return null;
  var standarDelivery = 'null';
  var standardDeliveryTime = 'null';
  var storePickup = 'null';
  shipping.forEach(function (item) {
    if (item.type === 'conventional') {
      standarDelivery = item.description;
      standardDeliveryTime = item.price;
    }
    if (item.type === 'store_pickup') {
      storePickup = item.description;
    }
  });
  return "entrega-padrao:".concat(slug(standarDelivery), ":").concat(slug(standardDeliveryTime), "|retira-loja:").concat(slug(storePickup), ":null");
};
var buildDimension8 = function buildDimension8(data) {
  var _data$product, _data$product$payment, _luizaOuroPaymentMeth, _luizaOuroPaymentMeth2, _luizaOuroPaymentMeth3, _luizaOuroPaymentMeth4, _luizaOuroPaymentMeth5, _data$product$install, _data$product2, _data$product2$instal, _data$product$install2, _data$product3, _data$product3$instal, _data$product$install3, _data$product4, _data$product4$instal;
  if ((data == null ? void 0 : data.pageName) === 'buybox') {
    return "cartao-luiza:null:null:null|cartao-credito:null:null:null";
  }
  var luizaOuroPaymentMethod = data == null ? void 0 : (_data$product = data.product) == null ? void 0 : (_data$product$payment = _data$product.paymentMethods) == null ? void 0 : _data$product$payment.find(function (method) {
    return method.id === 'luiza_ouro';
  });
  var firstInstallmentTotalAmount = (luizaOuroPaymentMethod == null ? void 0 : (_luizaOuroPaymentMeth = luizaOuroPaymentMethod.installmentPlans) == null ? void 0 : (_luizaOuroPaymentMeth2 = _luizaOuroPaymentMeth[0]) == null ? void 0 : _luizaOuroPaymentMeth2.totalAmount) || null;
  var installmentPlansLength = (luizaOuroPaymentMethod == null ? void 0 : (_luizaOuroPaymentMeth3 = luizaOuroPaymentMethod.installmentPlans) == null ? void 0 : _luizaOuroPaymentMeth3.length) || null;
  var lastInstallmentAmount = (luizaOuroPaymentMethod == null ? void 0 : (_luizaOuroPaymentMeth4 = luizaOuroPaymentMethod.installmentPlans) == null ? void 0 : (_luizaOuroPaymentMeth5 = _luizaOuroPaymentMeth4[installmentPlansLength - 1]) == null ? void 0 : _luizaOuroPaymentMeth5.installmentAmount) || null;
  var creditCardTotalAmount = (_data$product$install = data == null ? void 0 : (_data$product2 = data.product) == null ? void 0 : (_data$product2$instal = _data$product2.installment) == null ? void 0 : _data$product2$instal.totalAmount) !== null && _data$product$install !== void 0 ? _data$product$install : null;
  var creditCardQuantity = (_data$product$install2 = data == null ? void 0 : (_data$product3 = data.product) == null ? void 0 : (_data$product3$instal = _data$product3.installment) == null ? void 0 : _data$product3$instal.quantity) !== null && _data$product$install2 !== void 0 ? _data$product$install2 : null;
  var creditCardAmount = (_data$product$install3 = data == null ? void 0 : (_data$product4 = data.product) == null ? void 0 : (_data$product4$instal = _data$product4.installment) == null ? void 0 : _data$product4$instal.amount) !== null && _data$product$install3 !== void 0 ? _data$product$install3 : null;
  return "cartao-luiza:".concat(firstInstallmentTotalAmount, ":").concat(installmentPlansLength, ":").concat(lastInstallmentAmount, "|cartao-credito:").concat(creditCardTotalAmount, ":").concat(creditCardQuantity, ":").concat(creditCardAmount);
};
export var addToCartDimensions = function addToCartDimensions(data) {
  var _data$product5, _data$product5$seller, _data$product6, _data$product7, _data$product8, _data$product9, _data$product10, _data$product10$ratin, _data$product10$ratin2, _data$product11, _data$product11$ratin, _data$product11$ratin2, _data$product12, _data$product13, _data$product13$selle, _data$product14, _data$product14$selle, _data$product15, _data$product16, _data$product17, _buildDimension, _data$product18, _data$product18$shipp, _data$product18$shipp2, _data$product18$shipp3, _data$product18$shipp4, _data$product19, _data$product20, _data$product20$media, _data$product20$media2;
  var _formattedTagSeal = formattedTagSeal(data == null ? void 0 : data.product),
    seal = _formattedTagSeal.seal,
    tag = _formattedTagSeal.tag;
  return {
    dimension5: (data == null ? void 0 : (_data$product5 = data.product) == null ? void 0 : (_data$product5$seller = _data$product5.seller) == null ? void 0 : _data$product5$seller.category) || null,
    dimension7: buildDimension7(data == null ? void 0 : (_data$product6 = data.product) == null ? void 0 : _data$product6.price),
    dimension8: buildDimension8(data),
    dimension9: buildDimension9(data.product),
    dimension11: "".concat((data == null ? void 0 : (_data$product7 = data.product) == null ? void 0 : _data$product7.available) ? 'verdadeiro' : 'falso:indisponivel-cep'),
    dimension23: buildDimension23(data == null ? void 0 : (_data$product8 = data.product) == null ? void 0 : _data$product8.seller, data == null ? void 0 : (_data$product9 = data.product) == null ? void 0 : _data$product9.togglePdpSellerScore),
    dimension24: "qtd-av:".concat(data == null ? void 0 : (_data$product10 = data.product) == null ? void 0 : (_data$product10$ratin = _data$product10.rating) == null ? void 0 : (_data$product10$ratin2 = _data$product10$ratin.count) == null ? void 0 : _data$product10$ratin2.toString(), "|av:").concat(data == null ? void 0 : (_data$product11 = data.product) == null ? void 0 : (_data$product11$ratin = _data$product11.rating) == null ? void 0 : (_data$product11$ratin2 = _data$product11$ratin.score) == null ? void 0 : _data$product11$ratin2.toString()),
    dimension41: buildDimension41(data == null ? void 0 : (_data$product12 = data.product) == null ? void 0 : _data$product12.offerTags),
    dimension61: (data == null ? void 0 : (_data$product13 = data.product) == null ? void 0 : (_data$product13$selle = _data$product13.seller) == null ? void 0 : _data$product13$selle.id) || null,
    dimension62: (data == null ? void 0 : (_data$product14 = data.product) == null ? void 0 : (_data$product14$selle = _data$product14.seller) == null ? void 0 : _data$product14$selle.deliveryId) || null,
    dimension77: "nominal:".concat((data == null ? void 0 : data.pageName) === 'buybox' ? null : getNominalDiscountValue(data == null ? void 0 : (_data$product15 = data.product) == null ? void 0 : _data$product15.installment, data == null ? void 0 : (_data$product16 = data.product) == null ? void 0 : _data$product16.price), "|percentual:").concat(getPercentualDiscountValue(data == null ? void 0 : (_data$product17 = data.product) == null ? void 0 : _data$product17.price)),
    dimension86: seal,
    dimension87: tag,
    dimension112: (_buildDimension = buildDimension112(data == null ? void 0 : (_data$product18 = data.product) == null ? void 0 : (_data$product18$shipp = _data$product18.shipping) == null ? void 0 : (_data$product18$shipp2 = _data$product18$shipp[0]) == null ? void 0 : (_data$product18$shipp3 = _data$product18$shipp2.packages) == null ? void 0 : (_data$product18$shipp4 = _data$product18$shipp3[0]) == null ? void 0 : _data$product18$shipp4.deliveryTypes)) == null ? void 0 : _buildDimension.slice(0, 99),
    dimension115: wishlistItem(data == null ? void 0 : (_data$product19 = data.product) == null ? void 0 : _data$product19.variationId),
    dimension120: (data == null ? void 0 : (_data$product20 = data.product) == null ? void 0 : (_data$product20$media = _data$product20.media) == null ? void 0 : (_data$product20$media2 = _data$product20$media.images) == null ? void 0 : _data$product20$media2.length) || null
  };
};