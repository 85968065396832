import { isInternationalPrice } from '@magalu/mixer-utils';
var formatValue = function formatValue(value) {
  return value ? parseFloat(value).toFixed(2) : 'nao-calculado';
};
export var buildDimension9 = function buildDimension9(product) {
  var _product$tax, _product$tax2;
  if (!isInternationalPrice(product)) {
    return null;
  }
  var icms = formatValue(product == null ? void 0 : (_product$tax = product.tax) == null ? void 0 : _product$tax.icms);
  var importTax = formatValue(product == null ? void 0 : (_product$tax2 = product.tax) == null ? void 0 : _product$tax2.importValue);
  return "imposto:".concat(importTax, "|icms:").concat(icms);
};