import placeholderAttributes from '../utils/placeholderAttributes';
var addToCart = function addToCart(data) {
  var _structure$cookies, _structure$cookies$ac;
  var product = data.product,
    structure = data.structure;
  var userId = (structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.customer_id) || null;
  var attributes = placeholderAttributes(structure);
  return {
    cloud_retail: {
      attributes: attributes,
      eventType: 'add-to-cart',
      productDetails: [{
        product: {
          id: product.variationId
        },
        quantity: 1
      }],
      userInfo: {
        userId: userId
      }
    },
    event: 'cloud_retail'
  };
};
export default addToCart;