import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import placeholderAttributes from '../utils/placeholderAttributes';
var subCategory = function subCategory(_ref) {
  var data = _ref.data,
    structure = _ref.structure;
  var siteId = structure == null ? void 0 : structure.id;
  if (siteId === 'subcategory') {
    var _structure$cookies, _structure$cookies$ac, _data$search, _data$category, _data$category$, _data$category2, _data$category2$, _data$category2$$subc, _data$search2, _data$search3, _data$search3$paginat;
    var userId = (structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.customer_id) || null;
    var attributes = placeholderAttributes(structure);
    var attributionToken = (data == null ? void 0 : (_data$search = data.search) == null ? void 0 : _data$search.attributionToken) || null;
    var categoryLabel = data == null ? void 0 : (_data$category = data.category) == null ? void 0 : (_data$category$ = _data$category[0]) == null ? void 0 : _data$category$.id;
    var subCategories = data == null ? void 0 : (_data$category2 = data.category) == null ? void 0 : (_data$category2$ = _data$category2[0]) == null ? void 0 : (_data$category2$$subc = _data$category2$.subcategories) == null ? void 0 : _data$category2$$subc.flatMap(function (item) {
      return item.id;
    });
    var pageCategories = [categoryLabel].concat(_toConsumableArray(subCategories));
    var products = (data == null ? void 0 : (_data$search2 = data.search) == null ? void 0 : _data$search2.products) || [];
    var offset = data == null ? void 0 : (_data$search3 = data.search) == null ? void 0 : (_data$search3$paginat = _data$search3.pagination) == null ? void 0 : _data$search3$paginat.size;
    var productDetails = products.map(function (product) {
      return {
        product: {
          id: product.variationId
        }
      };
    });
    return {
      cloud_retail: {
        attributes: attributes,
        attributionToken: attributionToken,
        eventType: 'search',
        offset: offset,
        pageCategories: pageCategories,
        productDetails: productDetails,
        userInfo: {
          userId: userId
        }
      },
      event: 'cloud_retail'
    };
  }
  return null;
};
export default subCategory;